"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KEY_CODE = void 0;
var KEY_CODE = {
  ENTER: 13,
  ARROW_DOWN: 40,
  ARROW_UP: 38,
  TAB: 9
};
exports.KEY_CODE = KEY_CODE;