"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CopyButton", {
  enumerable: true,
  get: function get() {
    return _CopyButton.CopyButton;
  }
});

var _CopyButton = require("./CopyButton");