"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ValidationMessage", {
  enumerable: true,
  get: function get() {
    return _ValidationMessage.ValidationMessage;
  }
});

var _ValidationMessage = require("./ValidationMessage");