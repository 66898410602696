"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Flex", {
  enumerable: true,
  get: function get() {
    return _Flex.Flex;
  }
});

var _Flex = require("./Flex");