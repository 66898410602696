"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Autocomplete", {
  enumerable: true,
  get: function get() {
    return _Autocomplete.Autocomplete;
  }
});

var _Autocomplete = require("./Autocomplete");