"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TabFocusTrap", {
  enumerable: true,
  get: function get() {
    return _TabFocusTrap.TabFocusTrap;
  }
});

var _TabFocusTrap = require("./TabFocusTrap");