"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useOnClickOutside = useOnClickOutside;

var _react = require("react");

/**
 * Runs the given handler when a click event is fired outside the HTMLElement
 * the given RefObject points to.
 *
 * @param refs - RefObject[] pointing to a HTMLElement to track clicks outside
 * @param handler - Event handler to run on click outside
 */
function useOnClickOutside(refs, handler) {
  var noHandler = !handler;
  var handlerRef = (0, _react.useRef)(handler);
  (0, _react.useEffect)(function () {
    if (noHandler) {
      return;
    }

    var refContains = function refContains(ref, node) {
      return ref.current && ref.current.contains(node);
    };

    var listener = function listener(event) {
      if (!handlerRef.current || refs.some(function (ref) {
        return refContains(ref, event.target);
      })) {
        return;
      }

      handlerRef.current(event);
    };

    document.addEventListener('click', listener, {});
    return function () {
      document.removeEventListener('click', listener, {});
    };
  }, [noHandler, refs]);
}