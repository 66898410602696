"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DisplayText", {
  enumerable: true,
  get: function get() {
    return _DisplayText.DisplayText;
  }
});
Object.defineProperty(exports, "Heading", {
  enumerable: true,
  get: function get() {
    return _Heading.Heading;
  }
});
Object.defineProperty(exports, "Paragraph", {
  enumerable: true,
  get: function get() {
    return _Paragraph.Paragraph;
  }
});
Object.defineProperty(exports, "SectionHeading", {
  enumerable: true,
  get: function get() {
    return _SectionHeading.SectionHeading;
  }
});
Object.defineProperty(exports, "Subheading", {
  enumerable: true,
  get: function get() {
    return _Subheading.Subheading;
  }
});
Object.defineProperty(exports, "Typography", {
  enumerable: true,
  get: function get() {
    return _Typography.Typography;
  }
});

var _DisplayText = require("./DisplayText/DisplayText");

var _Heading = require("./Heading/Heading");

var _Paragraph = require("./Paragraph/Paragraph");

var _SectionHeading = require("./SectionHeading/SectionHeading");

var _Subheading = require("./Subheading/Subheading");

var _Typography = require("./Typography/Typography");